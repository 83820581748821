/* GLOBAL STYLES
-------------------------------------------------- */
/* Padding below the footer and lighter body text */

body {
  color: #5a5a5a;  
}
@media (min-width: 768px){
  body.isHomePage{
    overflow: hidden;
  }
}
body.isHomePage .section {    
  transition: height 0.3s ease-out;
}
@media (max-width: 767px){
  #navi-fullpage{
    display: none;
  }
  body.isHomePage .row{
    margin-right: 0;
    margin-left: 0;
  }
  body.isHomePage .section {    
    padding: 40px 0;
  }
  .col-md-6.exportRight .page-description{
    width: 100% !important;
  }
}
#navi-fullpage{
  position: fixed;
  right: 20px;
  top: 38%;
  z-index: 1000;
}
#navi-fullpage li{  
  list-style: none;
  text-align: center;
}
#navi-fullpage li i{
  color: #111;
  transition: all 0.3s ease-out;
  font-size: 13px;
}
#navi-fullpage li button.active i, #navi-fullpage li i:hover{
  color: #dc3545;  
  font-size: 16px;
}
#navi-fullpage li button{
  border: none;
  background-color: transparent;
}
.logo {
  width: 220px;
}
/* CUSTOMIZE THE CAROUSEL
-------------------------------------------------- */
  
/* Carousel base class */
.carousel{
  height: 100%;
  height: 100vh;
  overflow: hidden;  
}

/* Since positioning the image, we need to help out the caption */
.carousel-caption {
  bottom: 14rem;
  z-index: 10;
  left: 10%;
}
i.name-slide4 {  
  color: #5a5a5a;
}
.lead{
  font-weight: 350;
}
.carousel-item .carousel-caption h2{
  color: #444555;
  text-transform: uppercase;
  font-family: sans-serif;
  font-size: 1.6rem;
}
.lead , .address{
  color: #d7171f;
}
.carousel-item .carousel-caption h2, .lead , .address{
  font-weight: bold;
}

.carousel-item .carousel-caption .description{
  color: #111;
  margin-bottom: 10px;
}

.carousel-indicators li{
  background-color: #111 !important;
}

.carousel-control-next-icon, .carousel-control-prev-icon {
  width: 35px;
  height: 35px;
  background-color: #ccc;
  border-radius: 50%;
  padding: 20px;
  background-position: center center;
  background-size: 50%;
}
/* Declare heights because of positioning of img element */
.carousel-item {
  background-color: #777;
  }
.carousel .carousel-inner .bg {
background-repeat:no-repeat;
background-size:cover;
background-position: center right;
}
.carousel-item {
  height: 100vh;
  position: relative;
}
.carousel-item > img {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  height: 100vh;
}
.handleViewMiddle {
  vertical-align: middle;
}
/* MARKETING CONTENT
-------------------------------------------------- */

/*Export */
.wapExport .col-md-6 {
  position: relative;
}
.wapExport .col-md-6 .page-description{
  float: right;  
}
.exportRight{
  background: #eeeeee;
  height: 100%;
}
.col-md-6.exportLeft {
  color: #fff;
}
.col-md-6.exportRight .page-description{  
  -webkit-transition: all 1.1s ease-out;
  transition: all 1.1s ease-out;
  width: 400px;  
}
@media (min-width: 768px) { 
  .wapExport .col-md-6.exportRight .page-description.text-justify {
    position: absolute;
    left: 40%;
  }
  .sec-active.wapExport .col-md-6.exportRight .page-description.text-justify{
    left:30px;
  }
}

.fp-viewing-3 .col-md-6.exportRight .page-description{
  left: 30px;
}
.col-md-6 .page-description .section-title {
  font-size: 45px;
}
.col-md-6 .page-description .title {
  font-size: 22px;
  color: #d7171f;
  font-weight: normal;
  letter-spacing: -1.5px;
}
.col-md-6.exportRight .page-description p {
  font-size: 18px;
}
a.more-info {
  text-decoration: none;
  display: inline-block;
  padding: 10px 24px;
  color: #fff;
  background: #d7171f;
  border-radius: 20px;
  line-height: 1em;
  font-size: 16px;
  border: none;
  transition: all .35s ease-out;
}
a.more-info:hover {
  background: #ccc;
  color: #5a5a5a;
}

@media (max-width: 576px) { 
  .page-description, .one_half {
    width: 100%;       
  }  
  .video iframe{
    min-height: 195px   !important;
  }
  /* width="516" height="315" */
  .video-12 iframe{
    width: 100%;
    height: 315px;
  }
}

.video iframe{
  width: 100%    !important;  
  margin: 0 auto;
}
@media (min-width: 577px) { 
  .one_half, .one_third, .two_third, .one_fourth, .three_fourth {
    float: left;  
  }
  .video iframe{
    min-height: 305px   !important;
  }
  .video-12 iframe{
    width: 516px;
    height: 315px;
  }
}


.one_half, .one_third, .two_third, .one_fourth, .three_fourth {    
  position: relative;
  line-height: 24px;
  padding: 0 15px;
}
span.feature-item-media {
  background-position: 0 0;
  background-repeat: no-repeat;
}
span.feature-item-media {
  display: block;
  width: 190px;
  padding-right: 10px;
  height: 57px;
  float: left;
  font-size: 40px;
  line-height: 40px;
  font-weight: 600;
  text-align: right;
}
p.feature-item-content {
  display: inline-block;
  line-height: 30px;
  margin-bottom: 35px;
  width: 190px;
}
.last {
  margin-right: 0px !important;
  clear: right;
}
/******************************Export*/
/*New product*/
.future-txt-NewProduct{
  transition: all 6s ease-out;
  position: absolute;
  left: 30px;
}
.fp-viewing-4 .future-txt-NewProduct{
  left: 60%;
}
.new-product p{
  letter-spacing: 1px; 
}
/************New product*/

/*Begin Blog News*/
.blog-news-event{
  padding-top: 45px;
}
.title_blog-news-event{
  margin-bottom: 30px;
  font-size: 45px;
}
.blog-news-event img {
  width: 200px !important;
  height: 250px !important;
}
/*******************Blog News*/
/*Contact*/
h2.title-contact {
  font-size: 45px;
}
.cl-contact p{
  font-size: 18px;
}
p.address {
  font-weight: bold;
}
.addr-sub1 span {
  font-size: 20px; 
  vertical-align: middle;
}
.addr-sub1 i {
  font-size: 30px;
  color: #FF7F00; 
  display: inline-block;
  margin-right: 20px;
  padding-left: 30px;
  vertical-align: middle;
}
/**********Contact*/

/* Center align the text within the three columns below the carousel */
.marketing .col-lg-4 {
  margin-bottom: 1.5rem;
  text-align: center;
}
.marketing h3 {
  color: #5a5a5a;
}
.marketing h4, .cl-export h3 {
  font-weight: bold;
  color: #d92027;
}
.marketing .col-lg-4 p {
  margin-right: .75rem;
  margin-left: .75rem;
}


/* Featurettes
------------------------- */

.featurette-divider {
  margin: 5rem 0; /* Space out the Bootstrap <hr> more */
}

/* Thin out the marketing headings */
.featurette-heading {
  font-weight: 350;
  line-height: 1;
  letter-spacing: -.05rem;
}
.lead{
  font-size: 18px;
}
.jumbotron{
  border-radius: 0;
}
.jumbotron.new-product {
  margin-bottom: 0;
}

/* Technology*/
.technology img {
  max-width: 100%;
  width: 200px;
  height: auto;
}

/* #d92027 */
.technology p.section-subtitle, .technology p.plus {
  margin-bottom: 0;
  color: #5a5a5a;
  padding: 0;
  line-height: 30px;    
}

.technology p.plus {
    font-size: 45px;    
    color:#d92027;
    margin: 8px 0 20px;
  }
/*.........................................................*/

footer {
  padding: 40px 0;
}
.footer a, #copyright strong {
  color: #fff;
}
#copyright {
  color: #ccc;
}
.col-md-6.text-right.social img {
  padding: 5px;
  background-color: #fff;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin: 5px;
}
/* RESPONSIVE CSS
-------------------------------------------------- */
@media (max-width: 600px){
  .fss-dotted-scrollspy{
    display: none !important;
  }
  .navbar-brand.lg-desktop{
    display: none;
  }
}
@media (min-width: 601px){
  .navbar-brand.lg-moblie{
    display: none;
  }
}
@media (min-width: 40em) {
  /* Bump up size of carousel content */
  .carousel-caption p {
    margin-bottom: 1.25rem;
    font-size: 1.1rem;
    line-height: 1.4;
  }

  .featurette-heading {
    font-size: 45px;
  }  
}

.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}
@media (max-width: 768px){
    .wapExport .col-md-6 {
      display: unset !important;
      position: unset !important;
    }    
  }

/* Button Back to top */
#return-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.7);
  width: 50px;
  height: 50px;
  display: block;
  text-decoration: none;
  -webkit-border-radius: 35px;
  -moz-border-radius: 35px;
  border-radius: 35px;
  display: none;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
#return-to-top i {
  color: #fff;
  margin: 0;
  position: relative;
  left: 0px;
  top: 13px;
  font-size: 19px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
#return-to-top:hover {
  background: rgba(0, 0, 0, 0.9);
}
#return-to-top:hover i {
  color: #fff;
  top: 5px;
}
/*------------------------*/
.view1 {
  background-color: #30d5c7;
}

.view2 {
  background-color: #2abaae;
}

.view3 {
  background-color: #1c7b73;
}

.view4 {
  background-color: #13544f;
}

.view5 {
  background-color: #28d5cf;
}

.subview-a {
  background-color: #2abaae;
}

.subview-b {
  background-color: #0eab91;
}

.subview-c {
  background-color: #13ad58;
}

.subview-d {
  background-color: #45bca1;
}

.subview-e {
  background-color: #23dc94;
}

.info {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Arial, Tahoma;
  font-size: 32px;
}
.fss-mainview.fp-auto-height {
  height: auto !important; 
}
.row.stores-list .col-4{
  margin: 15px 0;
}
.titlePage {
  color: #d92027;
}
.stores-list .card {
  border: none;
  border-radius: 0;
}
.stores-list .card-body{
  padding: 1.25rem 0;
}
.mainTitlePage {
  padding: 20px;
  border: dotted 2px #d92027;
  color: #221E1F;
}
#breadcrumbs{
  font-weight: 900;
  height: 190px;
  background: #e9e9e9 url('./img/bgtop-product.jpg') no-repeat;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center bottom;
  margin-top: 70px;
}
#breadcrumbs_box {
  position: relative;
  padding: 16px 0;
  color: #666;
  font-size: 14px;
  font-weight: normal;
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
}
#breadcrumbs_box a{
  color: #5a5a5a;
}
.title-page{
  color: #000;
}
.sub-title-page{
  position: relative;
  padding-left: 15px;
  margin: 25px 0;
}
.sub-title-page i{
  position: absolute;
  top:-8px;
  font-size: 12px;
  left: 0;
}
.h2-title-page{
  color: #EB2026;
  font-size: 28px;
}
#bg-content-black-ginssen {
  background: url('./img/about_black_ginssen2.jpg') no-repeat;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center bottom;
  min-height: 900px;
  color: #fff;
  text-align: right;
  padding: 30px;
  margin: 20px 0;
}
.dac-trung img{
  width: 120px;
  height: 120px;
  margin: 15px 0;
}
.leaderline {
  background: url('./img/leaderline.png') no-repeat center right;
}
.nav-item .dropdown-menu{
  top:80% !important;
}
.nav-item.dropdown:hover .dropdown-menu {
  display: block;
}

.nav-item.dropdown .dropdown-toggle:active {
  /*Without this, clicking will make it sticky*/
    pointer-events: none;
}
.col-md-3.icon-oem-odm .content-icon-oem, .col-md-6.icon-oem-odm .content-icon-oem{
  border: 1px solid #ddd; 
  min-height: 150px;  
  margin: 10px 0;    
  padding: 10px;
}
.col-md-3.icon-oem-odm .content-icon-oem p, .col-md-6.icon-oem-odm .content-icon-oem p{
  width: 100% !important;
  text-align: center;
  margin-bottom: 0;
}
.text-oem-odm-bg{
  min-height: 360px;
  text-align: center;
  width: 100%;
  background: url('./img/oem-odm-bg.jpg') no-repeat center center;
}
.text-oem-odm-bg p{
  width: 100% !important;
  color: #fff;
  font-size: 30px;
}
.col-12.text-des-odm{
  margin: 40px 0;
  padding: 30px;  
  background-color: #fff;
  border: 1px solid #ddd;
}
.des-qulity-control ul li{
  list-style: none;
  padding-left: 16px;
  background: url("img/li_bullet.png") no-repeat 0 8px;
}
@media screen and (min-width: 600px) {
  #carouselExampleIndicators, .quality-silde, #carouselExampleIndicators .carousel-item, .quality-silde img{
    max-height: 500px !important;
  }
}
@media screen and (max-width: 600px) {
  #carouselExampleIndicators, .quality-silde, #carouselExampleIndicators .carousel-item, .quality-silde img{
    height: 200px !important;
  }
}
.col-10.btn-slide.row .col-2, .col-10.btn-slide.row .col-8{
  border-bottom: 1px solid #ddd;
  padding: 20px;
  line-height: 15px;
}
#carouselExampleIndicators.carousel{
  overflow: unset !important;
}
#carouselExampleIndicators .carousel-indicators li {
  width: 10px;
  height: 10px;
  border-radius: 100%;
}
#carouselExampleIndicators .carousel-indicators {
  bottom: -60px;
}
hr.divider {
  display: inline-block;
  width: 100%;
  border: 1px dotted #ddd;
  margin: 10px 0;
}
.col-12.des-rd-center ul, .col-sm-4.des-rd-center ul{
  border: 1px solid #ddd;  
  background: #EB2026;
  color: #fff;
  padding: 55px 50px;
}
.organ-div{
  border: 1px solid #ddd;
  padding-top: 50px;
  padding-bottom: 50px;
}
.row.border-year{
  margin: 30px 0;
}
.row.border-year .col-3 {
  border: 1px solid #ddd;
  padding: 15px;
  text-align: center;
}
.row.border-year .col-3 a{
  color: #888;
  width: 100%;
}

.col-6.text-right.history-right {
  border-right: 1px solid #d7171f;
}
.col-6.text-right.history-right::after{
  content: "";
  display: block;
  position: absolute;
  background-color: #fff;
  right: -7px;
  top: -5px;
  margin-top: 5px;
  margin-left: 2px;
  height: 13px;
  width: 13px;
  border: 1px solid #d7171f;
  border-radius: 9px;
}
h1.title-page img {  
  margin-bottom: 6px;
}
.row.wrap-product .col-6.col-sm-4{
  margin-top: 30px;
}
.row.wrap-product .card.item-product {
  height: 100%;
}
.porudct-list h1.title-page, .porudct-detail h1.title-page{
  font-size: 30px;
}
div#accordion {
  border-bottom: 1px solid rgba(0,0,0,.125);
}
.porudct-detail .card{
  border: none !important;  
  border-radius: 0;
  width: 100%;
}
.porudct-detail #accordion .btn{
  border-radius: 0;
}
.porudct-detail #accordion .card-link+.card-link {
  margin-left: 0rem;
}
.porudct-detail #accordion .card-header{
  background-color: transparent;
  padding: 0;
  border-left: none!important;
  border-right: none!important;
}
.porudct-detail #accordion .collapse {
  border-left: 1px solid rgba(0,0,0,.125);
  border-right: 1px solid rgba(0,0,0,.125);
}
.porudct-detail #accordion .btn.btn-outline-danger.card-link {  
  background: #dc3545;
  color: #fff;
}
.porudct-detail #accordion .btn.btn-outline-danger.collapsed.card-link {  
  background: transparent;
  color: #dc3545;
}
.porudct-detail #accordion .btn.btn-outline-danger.collapsed.card-link:hover {  
  background: #dc3545;
  color: #fff;
}
.mini-cart-detail-product .card-body{
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.quantity input{
  width: 100%;
  font-size: 12px;
}
p.main-price, p.sale-price, strong.main-price, strong.sale-price{
  margin-bottom:0; 
  color:  #dc3545;
  font-weight: bold;
}
p.main-price.has-sale, strong.main-price.has-sale{
  font-weight: normal !important;
  color: #444555;
  text-decoration: line-through;  
}
.porudct-detail .wrap-price{
  padding: 15px 0;
  border-top: 1px solid rgba(0,0,0,.125);
  border-bottom: 1px solid rgba(0,0,0,.125);
  position: relative;
}
.discount_rate_detail {
  position: absolute;
  z-index: 5;
  display: block;
  top: -6px;
  right: 20px;
  width: 37px;
  height: 42px;
  padding: 10px 0 0;
  line-height: 12px;
  text-align: center;
  background: url("./img/detail_sale_ico.png") no-repeat;
  font-family: 'tahoma';
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  right: 3px;
}
#fastCartModalLabel{
  color:  #dc3545;
}
#fastCartModal .modal-body .row{
  border: 1px solid rgba(0,0,0,.125);
  margin-bottom: 15px;
}
#fastCartModal .modal-body .row div{
  border-right: 1px solid rgba(0,0,0,.125);
}
#fastCartModal .modal-body .row div:last-child{
  border-right: none !important;
}
a.icon-social-media i {
  font-size: 30px;
  margin: 0 5px;
  color: #ccc;  
  transition: all 0.3s ease-out;
}
a.icon-social-media i.fab.fa-facebook:hover {
  color: #3B5998;
  font-size: 33px;
}
a.icon-social-media i.fab.fa-instagram:hover {
  color: #B72989;
  font-size: 33px;
}
a.icon-social-media i.fab.fa-youtube:hover {
  color: #C41C20;
  font-size: 33px;
}
a.icon-social-media i.fab.fa-linkedin-in:hover {
  color: #0271AE;
  font-size: 33px;
}
.stores-list .card {
  height: 100%;  
}
#collapseOne .stores-list p.card-text {
  min-height: 85px;
}
#collapseTwo .stores-list p.card-text{
  min-height: 50px;
}
.stores-list h5.card-title {
  font-size: 16px;
}
h3.my-3.des-short {
  font-size: 18px;
}
.ulChiSo{
  padding-left: 20px !important;
}
